  /************************************/
 /* Design/skin agnostic core styles */
 /************************************/
 .replay-ui-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
 .replay-aspect-ratio-fix {
    position: relative;
    max-height: 100vh;
}
 .replay-aspect-ratio-fix::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 56.25%;
    pointer-events: none;
}
 .replay-selector {
    text-align: center;
    align-self: center;
    cursor: pointer;
    position: relative;
}
 .replay-selector-item {
    white-space: nowrap;
}
 .replay-slider {
    flex-grow: 1;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
}
 .replay-slider-track {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
 .replay-slider-handle {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}
 .replay-button, .replay-toggle-button {
    overflow: hidden;
    text-align: center;
    align-self: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
 .replay-volume {
    display: flex;
}
 .replay-controls-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: visible;
    opacity: 1;
}
 .replay-timeline-information {
    position: absolute;
    width: 100%;
    height: 100%;
}
 .replay-timeline-information .replay-timeline-tooltip {
    position: absolute;
}
 .replay-playback-monitor {
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
}
 .replay-playback-monitor-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}
 .replay-playback-monitor-close-button {
    position: absolute;
    left: 0.8em;
}
 .replay-playback-monitor-version {
    text-align: right;
    position: absolute;
    right: 1.6em;
    top: 1em;
    margin: 0;
    padding: 0;
    opacity: 0.6;
}
 .replay-playback-monitor-stream-state {
    position: absolute;
    max-width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    table-layout: fixed;
}
 .replay-playback-monitor-stream-state td, .replay-playback-monitor-stream-state th {
    vertical-align: top;
}
 .replay-playback-monitor-property-name {
    text-align: right;
}
 .replay-playback-monitor-previous-value, .replay-playback-monitor-current-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
 .replay-video-streamer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: black;
    display: inline-block;
    vertical-align: baseline;
}
 .replay-mock-video-streamer {
    background: #444;
}
 .replay-mock-video-streamer > div {
    text-align: center;
    color: white;
    font-weight: bold;
    padding-top: 20px;
}
 /****************/
 /* Default skin */
 /****************/
 /* This file centralizes the look and feel. */
 .replay {
    font-size: 12px;
    line-height: normal;
}
 .replay-controls-bar {
    height: 50px;
    padding: 0 10px;
    text-align: center;
}
 .replay-narrow .replay-controls-bar {
    height: 75px;
    align-items: end;
    justify-content: space-between;
}
 .replay-narrow .replay-controls-bar > * {
    align-self: flex-end;
}
 .replay-slider {
    height: 50px;
    margin: 0 10px;
}
 .replay-slider-track {
    margin: 24px 0;
    height: 2px;
}
 .replay-slider-handle {
    height: 16px;
    width: 16px;
    border-radius: 8px;
}
 .replay-button, .replay-toggle-button, .replay-selector {
    width: 40px;
    height: 50px;
}
 .replay-narrow .replay-button, .replay-narrow .replay-toggle-button, .replay-narrow .replay-selector {
    width: 40px;
}
 .replay-button svg, .replay-toggle-button svg {
    display: inline;
    vertical-align: baseline;
    transform: translateY(3px);
}
 .replay-selector-items {
    position: absolute;
    bottom: 50px;
    padding-top: 5px;
    transform: translateX(-50%);
    left: 50%;
    visibility: hidden;
}
 .replay-selector:hover .replay-selector-items, .replay-expanded .replay-selector-items {
    visibility: visible;
}
 .replay-selector-item > div {
    padding: 5px 15px;
    font-size: 14px;
    text-align: left;
}
 .replay-selector-item.replay-selected {
    font-weight: bold;
    color: white;
}
 .replay-timeline {
    flex-grow: 3;
}
 .replay-timeline-information .replay-timeline-tooltip {
    bottom: 100%;
    padding: 3px 8px;
    transform: translateX(-50%);
}
 .replay-narrow .replay-timeline {
    height: 40px;
    width: calc(100% - 40px);
    margin: 0 20px;
    position: absolute;
    top: 0;
    left: 0;
}
 .replay-narrow .replay-timeline-track {
    margin: 19px 0;
}
 .replay-time-display {
    font-size: 11px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
 .replay-time-display span {
    margin: 0 10px;
    display: block;
}
 .replay-narrow .replay-time-display span {
    margin: 0 4px;
}
 .replay-time-display span:nth-child(2) {
    margin-top: 0px;
    padding-top: 0px;
    border-top: 1px solid;
}
 .replay-volume {
    flex: 2 1 0;
    max-width: 220px;
    min-width: 120px;
}
 .replay-volume-disabled {
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 40px;
    min-width: 40px;
}
 .replay-skip-button {
    position: relative;
    font-size: 7px;
}
 .replay-skip-button span {
    margin-top: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
 .replay-skip-button span svg {
    margin-top: -5px;
}
 .replay-goto-live-button {
    white-space: nowrap;
    min-width: 50px;
    width: auto;
}
 /*
.replay-goto-live-button {
    min-width: 50px;
    width: auto;
    padding: 0 10px;
}

.replay-goto-live-button span:first-child {
    margin-right: 5px;
}

*/
 .replay-buffering-indicator {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
}
 .replay-is-fullscreen .replay-buffering-indicator {
    height: 100px;
    width: 100px;
}
 .replay-buffering-indicator.replay-buffering {
    visibility: visible;
}
 .replay-exit-button {
    top: 2%;
    right: 1%;
    position: absolute;
    opacity: 1;
}
 .replay-is-fullscreen .replay-exit-button, .replay-is-user-inactive .replay-exit-button {
    visibility: hidden;
    opacity: 0;
}
 .replay-playback-monitor {
    top: 3%;
    bottom: 60px;
    left: 6%;
    right: 6%;
    font-size: 10px;
    transition: bottom 0.3s;
}
 /*.replay-is-user-inactive .replay-playback-monitor {
    bottom: 3%;
    transition-delay: 0.4s;
}*/
 .replay-playback-monitor td, .replay-playback-monitor th {
    padding: 2px 8px;
}
 .replay-playback-monitor-stream-state {
    top: 0.5em;
}
 td.replay-playback-monitor-current-value {
    max-width: 160px;
}
 td.replay-playback-monitor-previous-value {
    max-width: 160px;
}
 .replay-controls-bar, .replay-selector-items, .replay-exit-button, .replay-playback-monitor-close-button, .replay-buffering-indicator, .replay-goto-live-button.replay-toggled-on:hover {
    color: #bbb;
}
 .replay-slider-handle {
    background-color: #bbb;
}
 .replay {
    background-color: #222;
}
 .replay-controls-bar, .replay-selector-items {
    background-color: rgba(32,32,32,0.8);
}
 .replay-slider-track, .replay-selector-item:hover {
    background-color: #888;
}
 .replay-selector-item:focus > * {
    background-color: #888;
}
 .replay-slider-handle:hover {
    background-color: white;
}
 .replay-slider:focus .replay-slider-handle {
    background-color: white;
}
 .replay-timeline-information .replay-timeline-tooltip {
    background-color: rgba(32,32,32,0.8);
}
 .replay-button:hover, .replay-toggle-button:hover {
    color: white;
}
 .replay-button:focus > *, .replay-toggle-button:focus > * {
    color: white;
}
 .replay-button:focus, .replay-button > :focus, .replay-toggle-button:focus, .replay-toggle-button > :focus, 
.replay-slider:focus, .replay-slider-handle:focus, .replay-selector-item:focus, .replay-selector-item > :focus {
    outline: none;
}
 .replay-goto-live-button.replay-toggled-on:hover {
    cursor: default;
}
 .replay-time-display span:nth-child(2) {
    border-top-color: rgba(128,128,128,0.5);
}
 .replay-playback-monitor {
    color: white;
    background-color: rgba(20, 20, 20, 0.9);
    cursor: default;
}
 td.replay-playback-monitor-current-value {
    opacity: 0.9;
}
 td.replay-playback-monitor-previous-value {
    opacity: 0.6;
}
 .replay-buffering-indicator {
    transition: opacity 1s, visibility 0s linear 0.5s;
    opacity: 0;
}
 .replay-buffering-indicator.replay-buffering {
    opacity: 1;
    transition-delay: 0s;
}
 @-webkit-keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
 @keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
 .replay-buffering-indicator .animated {
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
    transform-origin: 18px 18px;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
}
 .replay-buffering-indicator.replay-buffering .animated {
    -webkit-animation-play-state: running;
            animation-play-state: running;
}
 .replay-controls-bar {
    transition: visibility 0.5s, opacity 0.5s;
    cursor: default;
}
 .replay-exit-button:hover {
    visibility: visible;
    opacity: 1;
}
 .replay-is-user-inactive .replay-controls-bar {
    visibility: hidden;
    opacity: 0;
}
 @media (hover) {
    .replay-controls-bar:hover {
        cursor: default;
        visibility: visible;
        opacity: 1;
    }
}
 .replay-is-paused .replay-controls-bar {
    cursor: default;
    visibility: visible;
    opacity: 1;
}
 .replay-button, .replay-toggle-button {
    transition: color 0.5s;
}
 .replay-selector-items {
    opacity: 0;
    transition: opacity 0.5s, visibility 0s linear 0.5s;
}
 .replay-selector:hover .replay-selector-items, .replay-expanded .replay-selector-items {
    opacity: 1;
    transition-delay: 0s;
}
 .replay-selector-item {
    transition: background-color 0.2s ease-in-out;
}
 .replay-slider-handle {
    transition: background-color 0.5s;
}
 /* Only interesting if handle movement per 0.25s interval update is > 1 pixel. I.e. duration < timeline width / 4  */
 .replay-is-playing.replay-is-user-active .replay-timeline-handle {
    transition: left 0.25s;
}
 .replay-timeline.replay-dragging .replay-timeline-handle, .replay-timeline.replay-clicked .replay-timeline-handle  {
    transition: left 0s;
}
 .replay-timeline-information .replay-timeline-tooltip {
    visibility: hidden;
    opacity: 0;
}
 .replay-timeline-information .replay-timeline-tooltip-visible {
    opacity: 1;
    visibility: visible;
}
 .replay-timeline-information .replay-timeline-tooltip {
    transition: visibility 0.2s, opacity 0.2s;
}
 .replay-exit-button.replay-button {
    transition: visibility 0.5s, opacity 0.5s, color 0.5s;
}
 .replay {
    font-family: sans-serif;
}
 .replay-slider-handle:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}
 .replay-ui-container:focus {
    outline: none;
}
 /* Aesthetic vertical alignment and correction of icons */
 .replay-skip-button, .replay-goto-live-button svg {
    transform: translateY(2px);
}
 .replay-audio-selector .replay-toggle-button {
    transform: translateY(1px);
}
 .replay-quality-selector .replay-selector-toggle {
    transform-origin: 50% 50%;
    transform: scale(0.85);
}
